export class Token {
  public readonly audience: string[];
  public readonly permissions: string[];
  public readonly scope: string[];
  constructor(token: any) {
    this.audience = token.aud;
    this.permissions = token.permissions;
    this.scope = token.scope.split(" ");
  }
}
