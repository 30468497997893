/* tslint:disable */
/* eslint-disable */
/**
 * MiPuVer API
 * API docs for MiPuVer
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from "./configuration";
import globalAxios, { AxiosPromise, AxiosInstance } from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "./common";
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from "./base";
import { MultiPolygon, Point } from "geojson";

/**
 *
 * @export
 * @enum {string}
 */
export enum AreaType {
  vgs = "vgs",
  kgs2 = "kgs2",
  kgs5 = "kgs5",
  kgs8 = "kgs8",
  kgs11 = "kgs11",
  kgs14 = "kgs14",
  kgs17 = "kgs17",
}

/**
 *
 * @export
 * @interface CenterPointDto
 */
export interface CenterPointDto {
  /**
   *
   * @type {string}
   * @memberof CenterPointDto
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof CenterPointDto
   */
  communeName: string;
  /**
   *
   * @type {string}
   * @memberof CenterPointDto
   */
  kgs: string;
  /**
   *
   * @type {Array<Point>}
   * @memberof CenterPointDto
   */
  centerPoints: Array<Point>;
  /**
   *
   * @type {Point}
   * @memberof CenterPointDto
   */
  areaCenter: Point;
  /**
   *
   * @type {MultiPolygon}
   * @memberof CenterPointDto
   */
  area: MultiPolygon;
  /**
   *
   * @type {AreaType}
   * @memberof CenterPointDto
   */
  areaType: AreaType;
  /**
   *
   * @type {number}
   * @memberof CenterPointDto
   */
  quality: number;
}
/**
 *
 * @export
 * @interface ImprovableArea
 */
export interface ImprovableArea {
  /**
   *
   * @type {string}
   * @memberof ImprovableArea
   */
  kgs: string;
  /**
   *
   * @type {string}
   * @memberof ImprovableArea
   */
  title: string;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function(
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appControllerGetProtected: async (
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/protected`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async appControllerGetProtected(
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.appControllerGetProtected(
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DefaultApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appControllerGetProtected(options?: any): AxiosPromise<void> {
      return localVarFp
        .appControllerGetProtected(options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public appControllerGetProtected(options?: any) {
    return DefaultApiFp(this.configuration)
      .appControllerGetProtected(options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * GeometryApi - axios parameter creator
 * @export
 */
export const GeometryApiAxiosParamCreator = function(
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary retrieve areas in region
     * @param {string} kgs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    geometryControllerGetImprovedPoints: async (
      kgs: string,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'kgs' is not null or undefined
      assertParamExists("geometryControllerGetImprovedPoints", "kgs", kgs);
      const localVarPath = `/api/v1/geometry/improved-location/{kgs}`.replace(
        `{${"kgs"}}`,
        encodeURIComponent(String(kgs)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary retrieve areas in region
     * @param {'vgs' | 'kgs2' | 'kgs5' | 'kgs8' | 'kgs11' | 'kgs14' | 'kgs17'} areaType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    geometryControllerGetPotentialImprovements: async (
      areaType: "vgs" | "kgs2" | "kgs5" | "kgs8" | "kgs11" | "kgs14" | "kgs17",
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'areaType' is not null or undefined
      assertParamExists(
        "geometryControllerGetPotentialImprovements",
        "areaType",
        areaType,
      );
      const localVarPath = `/api/v1/geometry/potential-improvements`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (areaType !== undefined) {
        localVarQueryParameter["areaType"] = areaType;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary update center point
     * @param {string} kgs
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    geometryControllerUpdateCenterPoint: async (
      kgs: string,
      body: object,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'kgs' is not null or undefined
      assertParamExists("geometryControllerUpdateCenterPoint", "kgs", kgs);
      // verify required parameter 'body' is not null or undefined
      assertParamExists("geometryControllerUpdateCenterPoint", "body", body);
      const localVarPath = `/api/v1/geometry/improved-location/{kgs}`.replace(
        `{${"kgs"}}`,
        encodeURIComponent(String(kgs)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * GeometryApi - functional programming interface
 * @export
 */
export const GeometryApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = GeometryApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary retrieve areas in region
     * @param {string} kgs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async geometryControllerGetImprovedPoints(
      kgs: string,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CenterPointDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.geometryControllerGetImprovedPoints(
        kgs,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary retrieve areas in region
     * @param {'vgs' | 'kgs2' | 'kgs5' | 'kgs8' | 'kgs11' | 'kgs14' | 'kgs17'} areaType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async geometryControllerGetPotentialImprovements(
      areaType: "vgs" | "kgs2" | "kgs5" | "kgs8" | "kgs11" | "kgs14" | "kgs17",
      options?: any,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<ImprovableArea>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.geometryControllerGetPotentialImprovements(
        areaType,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary update center point
     * @param {string} kgs
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async geometryControllerUpdateCenterPoint(
      kgs: string,
      body: object,
      options?: any,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.geometryControllerUpdateCenterPoint(
        kgs,
        body,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * GeometryApi - factory interface
 * @export
 */
export const GeometryApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = GeometryApiFp(configuration);
  return {
    /**
     *
     * @summary retrieve areas in region
     * @param {string} kgs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    geometryControllerGetImprovedPoints(
      kgs: string,
      options?: any,
    ): AxiosPromise<CenterPointDto> {
      return localVarFp
        .geometryControllerGetImprovedPoints(kgs, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary retrieve areas in region
     * @param {'vgs' | 'kgs2' | 'kgs5' | 'kgs8' | 'kgs11' | 'kgs14' | 'kgs17'} areaType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    geometryControllerGetPotentialImprovements(
      areaType: "vgs" | "kgs2" | "kgs5" | "kgs8" | "kgs11" | "kgs14" | "kgs17",
      options?: any,
    ): AxiosPromise<Array<ImprovableArea>> {
      return localVarFp
        .geometryControllerGetPotentialImprovements(areaType, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary update center point
     * @param {string} kgs
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    geometryControllerUpdateCenterPoint(
      kgs: string,
      body: object,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .geometryControllerUpdateCenterPoint(kgs, body, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * GeometryApi - object-oriented interface
 * @export
 * @class GeometryApi
 * @extends {BaseAPI}
 */
export class GeometryApi extends BaseAPI {
  /**
   *
   * @summary retrieve areas in region
   * @param {string} kgs
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GeometryApi
   */
  public geometryControllerGetImprovedPoints(kgs: string, options?: any) {
    return GeometryApiFp(this.configuration)
      .geometryControllerGetImprovedPoints(kgs, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary retrieve areas in region
   * @param {'vgs' | 'kgs2' | 'kgs5' | 'kgs8' | 'kgs11' | 'kgs14' | 'kgs17'} areaType
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GeometryApi
   */
  public geometryControllerGetPotentialImprovements(
    areaType: "vgs" | "kgs2" | "kgs5" | "kgs8" | "kgs11" | "kgs14" | "kgs17",
    options?: any,
  ) {
    return GeometryApiFp(this.configuration)
      .geometryControllerGetPotentialImprovements(areaType, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary update center point
   * @param {string} kgs
   * @param {object} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GeometryApi
   */
  public geometryControllerUpdateCenterPoint(
    kgs: string,
    body: object,
    options?: any,
  ) {
    return GeometryApiFp(this.configuration)
      .geometryControllerUpdateCenterPoint(kgs, body, options)
      .then(request => request(this.axios, this.basePath));
  }
}
