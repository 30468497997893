/* eslint-disable @typescript-eslint/no-unused-vars */
import Vue from "vue";
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from "vue-router";
import { authGuard } from "../components/auth/authGuard";
import store from "../store";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    component: () =>
      import(
        /* webpackChunkName: "improvement" */ "@/components/improvement/Layout.vue"
      ),
    beforeEnter: authGuard,
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach(async (to: Route, from: Route, next: NavigationGuardNext) => {
  await (store as any).restored;
  next();
});

export default router;
