import { AreaType, CenterPointDto, ImprovableArea } from "../../api/types";

export const AreaTypeMapBoundaries = {
  [AreaType.kgs2]: {
    minZoom: 5,
    initialZoom: 7,
  },
  [AreaType.kgs5]: {
    minZoom: 8,
    initialZoom: 10,
  },
  [AreaType.vgs]: {
    minZoom: 9,
    initialZoom: 11,
  },
  [AreaType.kgs8]: {
    minZoom: 9,
    initialZoom: 11,
  },
  [AreaType.kgs11]: {
    minZoom: 8,
    initialZoom: 9,
  },
  [AreaType.kgs14]: {
    minZoom: 8,
    initialZoom: 9,
  },
  [AreaType.kgs17]: {
    minZoom: 8,
    initialZoom: 9,
  },
};

export enum ImprovementActions {
  load = "load",
  updateActiveArea = "updateActiveArea",
  setCurrentMap = "setCurrentMap",
  setActiveLayer = "setActiveLayer",
  updateSelectedKgs = "updateSelectedKgs",
  updateCurrentPosition = "updateCurrentPosition",
  confirmNewCentroid = "confirmNewCentroid",
  setNextSelectedKgs = "setNextSelectedKgs",
  setPrevSelectedKgs = "setPrevSelectedKgs",
}

export enum ImprovementGetters {}

export enum ImprovementMutations {
  setCurrentMap = "setCurrentMap",
  setActiveLayer = "setActiveLayer",
  setActiveArea = "setActiveArea",
  setKgsValues = "setKgsValues",
  setSelectedKgs = "setSelectedKgs",
  setImprovement = "setImprovementPoints",
  setCenterPoint = "setCenterPoint",
}

export enum ImprovementState {
  currentMap = "currentMap",
  activeLayer = "activeLayer",
  activeArea = "activeArea",
  kgsValues = "kgsValues",
  selectedKgs = "selectedKgs",
  improvement = "improvement",
}

export type State = {
  currentMap: string;
  activeLayer: string;
  activeArea: AreaType;
  kgsValues: ImprovableArea[];
  selectedKgs?: ImprovableArea;
  improvement?: CenterPointDto;
};
