import Vue from "vue";
import { Module } from "vuex";
import { AreaType } from "../../api/types";
import { add, set } from "../helpers";
import { actions } from "./actions";
import {
  ImprovementGetters,
  ImprovementMutations,
  ImprovementState,
  State,
} from "./types";

export const improvementModule: Module<State, any> = {
  namespaced: true,
  state: {
    currentMap: "iibmap",
    activeLayer: "pricelayer_hk",
    activeArea: AreaType.kgs8,
    kgsValues: [],
    improvement: undefined,
    selectedKgs: undefined,
  },
  mutations: {
    [ImprovementMutations.setCurrentMap]: set(ImprovementState.currentMap),
    [ImprovementMutations.setActiveLayer]: set(ImprovementState.activeLayer),
    [ImprovementMutations.setActiveArea]: set(ImprovementState.activeArea),
    [ImprovementMutations.setKgsValues]: set(ImprovementState.kgsValues),
    [ImprovementMutations.setSelectedKgs]: set(ImprovementState.selectedKgs),
    [ImprovementMutations.setImprovement]: set(ImprovementState.improvement),
    [ImprovementMutations.setCenterPoint]: (
      state,
      payload: { position: GeoJSON.Point; index: number },
    ) => {
      if (state.improvement) {
        Vue.set(
          state.improvement.centerPoints,
          payload.index,
          payload.position,
        );
      }
    },
  },

  actions: actions,
  getters: {},
};
