import Vue from "vue";
import { Auth0Plugin } from "../components/auth";
import router from "../router";

export const domain = "login.iib-it.de";
export const clientId = process.env.VUE_APP_CLIEND_ID;
export const audience = "https://admin-api.iib-it.de";

Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: (appState: any) => {
    console.log(appState);
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname,
    );
  },
});
