




import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";

@Component({
  name: "App",
  components: {},
})
export default class App extends Vue {
  @Watch("$auth.loading", { deep: true })
  async authLoadingWatch(value: boolean) {
    const token = await this.$auth.getTokenSilently({});
    console.log(token);
  }
}
