import { LatLng } from "leaflet";
import { ActionTree } from "vuex";
import { RootMutations } from "..";
import {
  AreaType,
  Configuration,
  GeometryApi,
  ImprovableArea,
} from "../../api/types";
import { getInstance } from "../../components/auth";
import { pointFromLatLng } from "../helpers";
import { ImprovementActions, ImprovementMutations, State } from "./types";

export const actions: ActionTree<State, any> = {
  async [ImprovementActions.load]({ dispatch, state }) {
    await dispatch(ImprovementActions.updateActiveArea, state.activeArea);
    if (!state.improvement && state.kgsValues.length > 0) {
      await dispatch(ImprovementActions.updateSelectedKgs, state.kgsValues[0]);
    }
  },

  async [ImprovementActions.updateActiveArea](
    { commit, dispatch, state },
    payload: AreaType,
  ) {
    // if (state.activeArea === payload) {
    //   return;
    // }

    const api = new GeometryApi(
      new Configuration({
        basePath: process.env.VUE_APP_BASE_URL,
        accessToken: getInstance().jwtToken || "",
      }),
      process.env.VUE_APP_BASE_URL,
    );

    const improvements = await api.geometryControllerGetPotentialImprovements(
      payload,
    );
    commit(ImprovementMutations.setKgsValues, improvements.data);
    commit(ImprovementMutations.setActiveArea, payload);
  },

  async [ImprovementActions.setNextSelectedKgs]({ dispatch, state }) {
    const index = state.kgsValues.findIndex(
      (area: ImprovableArea) => area.kgs === state.selectedKgs?.kgs,
    );
    let nextIndex = index + 1;
    if (nextIndex > state.kgsValues.length - 1) {
      nextIndex = 0;
    }
    await dispatch(
      ImprovementActions.updateSelectedKgs,
      state.kgsValues[nextIndex],
    );
  },

  async [ImprovementActions.setPrevSelectedKgs]({ dispatch, state }) {
    const index = state.kgsValues.findIndex(
      (area: ImprovableArea) => area.kgs === state.selectedKgs?.kgs,
    );
    let nextIndex = index - 1;
    if (nextIndex < 0) {
      nextIndex = state.kgsValues.length - 1;
    }
    await dispatch(
      ImprovementActions.updateSelectedKgs,
      state.kgsValues[nextIndex],
    );
  },

  [ImprovementActions.updateSelectedKgs]: async (
    { commit, dispatch, state },
    payload?: ImprovableArea,
  ) => {
    if (!payload || state.selectedKgs === payload) {
      return;
    }

    const api = new GeometryApi(
      new Configuration({
        basePath: process.env.VUE_APP_BASE_URL,
        accessToken: getInstance().jwtToken || "",
      }),
      process.env.VUE_APP_BASE_URL,
    );

    try {
      commit(RootMutations.setLoading, true, { root: true });
      const response = await api.geometryControllerGetImprovedPoints(
        payload.kgs,
      );
      commit(ImprovementMutations.setSelectedKgs, payload);
      commit(ImprovementMutations.setImprovement, response.data);
    } finally {
      commit(RootMutations.setLoading, false, { root: true });
    }
  },

  [ImprovementActions.updateCurrentPosition](
    { commit, state },
    payload: { position: LatLng; index: number },
  ) {
    if (state.improvement) {
      if (payload.index === -1) {
        state.improvement.areaCenter = pointFromLatLng(payload.position);
        commit(ImprovementMutations.setImprovement, state.improvement);
      } else {
        commit(ImprovementMutations.setCenterPoint, {
          index: payload.index,
          position: pointFromLatLng(payload.position),
        });
      }
    }
  },

  async [ImprovementActions.confirmNewCentroid](
    { commit, dispatch, state },
    payload: { index: number },
  ) {
    const api = new GeometryApi(
      new Configuration({
        basePath: process.env.VUE_APP_BASE_URL,
        accessToken: getInstance().jwtToken || "",
      }),
      process.env.VUE_APP_BASE_URL,
    );
    let newCentroid;
    if (payload.index === -1) {
      newCentroid = state.improvement?.areaCenter;
    } else {
      newCentroid = state.improvement?.centerPoints[payload.index];
    }
    await api.geometryControllerUpdateCenterPoint(
      state.selectedKgs?.kgs!,
      newCentroid!,
    );
    commit(ImprovementMutations.setImprovement, undefined);
    await dispatch(ImprovementActions.load);
  },
};
