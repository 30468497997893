import { LatLngBounds } from "leaflet";
import Vue from "vue";
import Vuex from "vuex";
import { namespace } from "vuex-class";
import VuexPersistence, { AsyncStorage } from "vuex-persist";
import * as localforage from "localforage";
import { improvementModule } from "./improvements";
import { set } from "./helpers";

Vue.use(Vuex);
export const improvement = namespace("improvement");

type State = {
  loading: boolean;
};

export enum RootState {
  loading = "loading",
}

const vuexLocal = new VuexPersistence<State>({
  storage: localforage as AsyncStorage,
  asyncStorage: true,
  modules: ["improvement"],
});

export enum RootMutations {
  setLoading = "setLoading",
}

export enum RootActions {
  clearAll = "clearAll",
}

const store = new Vuex.Store<State>({
  state: {
    loading: false,
  },
  plugins: [vuexLocal.plugin],

  mutations: {
    [RootMutations.setLoading]: set(RootState.loading),
  },

  actions: {
    [RootActions.clearAll]: async () => {
      await localforage.clear();
    },
  },

  modules: {
    improvement: improvementModule,
  },
});

export default store;
